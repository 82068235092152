import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
import {ClassificationInfo} from "../dto/classification/classification-info.dto";
import {ClassificationRequest} from "../dto/classification/classification-request.dto";
import { ClassificationFileInfo } from "../dto/classification/files/classification-file-info.dto";
import { GetClassificationFilesRequest } from "../dto/classification/files/get-classification-files-request.dto";
import { Page } from "../dto/common/page";
import { UploadClassificationFileRequest } from "../dto/classification/files/upload-classification-file-request.dto";

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {

  private baseUrl: string = `${environment.apiUrl}/classifications`;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
  ) {

  }

  reloadDatabase(): Observable<void> {
    const url = `${this.baseUrl}/reload-database`;
    return this.http.post<void>(url, null);
  }

  getClassifications(): Observable<ClassificationInfo[]> {
    const url = `${this.baseUrl}`;
    return this.http.get<ClassificationInfo[]>(url);
  }

  saveClassification(classificationRequest: ClassificationRequest): Observable<ClassificationInfo> {
    const url = `${this.baseUrl}`;
    return this.http.post<ClassificationInfo>(url, classificationRequest, { headers: this.headers });
  }

  deleteClassification(id: number): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url);
  }

  getClassificationFiles(getClassificationFilesRequest: GetClassificationFilesRequest): Observable<Page<ClassificationFileInfo>> {
    const url = `${this.baseUrl}/files`;
    return this.http.post<Page<ClassificationFileInfo>>(url, getClassificationFilesRequest);
  }

  deleteClassificationFile(id: number): Observable<void> {
    const url = `${this.baseUrl}/files/${id}`;
    return this.http.delete<void>(url);
  }

  uploadClassificationFile(uploadClassificationFileRequest: UploadClassificationFileRequest): Observable<any> {
    const url = `${this.baseUrl}/files/upload`;
    return this.http.post<any>(url, uploadClassificationFileRequest);
  }

}
