<div class="collapse navbar-collapse" id="navbarVerticalCollapse">
  <div class="navbar-vertical-content">
    <ul class="navbar-nav flex-column" id="navbarVerticalNav">
      <li class="nav-item">
        <p class="navbar-vertical-label">
          Menú
        </p>
        <div class="nav-item-wrapper">
          <a
            class="nav-link label-1"
            [routerLink]="['']"
            (click)="goToLink('admin/classifications')"
            role="button">
            <div class="d-flex align-items-center">
              <span class="nav-link-icon text-success">
                <i class="fa fa-layer-group"></i>
              </span>
              <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Clasificaciones
                </span>
              </span>
            </div>
          </a>
        </div>
        <div class="nav-item-wrapper">
          <a
            class="nav-link label-1"
            [routerLink]="['']"
            (click)="goToLink('admin/questions-answers')"
            role="button">
            <div class="d-flex align-items-center">
              <span class="nav-link-icon text-primary">
                <i class="fa fa-question"></i>
              </span>
              <span class="nav-link-text-wrapper">
                <span class="nav-link-text">
                  Preguntas y respuestas
                </span>
              </span>
            </div>
          </a>
        </div>
      </li>
    </ul>
  </div>
</div>

