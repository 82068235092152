import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { layoutComponents } from "./imports/components/layout-imports";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClassificationsComponent } from "./components/admin/classifications/classifications.component";
import { ToastrModule } from "ngx-toastr";
import { ClassificationFilesComponent } from "./components/admin/classifications/classification-files/classification-files.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { AlfonsinaComponent } from "./components/alfonsina/alfonsina.component";
import { QuestionsAnswersComponent } from "./components/admin/questions-answers/questions-answers.component";
import { SignInComponent } from "./components/auth/sign-in/sign-in.component";
import {AuthInterceptor} from "./services/interceptor/auth.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    ...layoutComponents,
    AlfonsinaComponent,
    ClassificationsComponent,
    ClassificationFilesComponent,
    QuestionsAnswersComponent,
    SignInComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule.forRoot({
      type: 'ball-scale-multiple'
    }),
    UppyAngularDashboardModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
