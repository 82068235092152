<div class="mb-9">
  <div class="row g-3">
    <div class="col-auto">
      <h2 class="mb-2">
        Preguntas y respuestas
      </h2>
      <p class="text-700 lh-sm mb-2">
        Administra las preguntas y respuestas que serán utilizadas.
      </p>
    </div>
  </div>
  <div class="mb-4">
    <div class="row g-3">
      <div class="col-md-auto col-sm-12">
        <div class="search-box">
          <form
            class="position-relative"
            data-bs-toggle="search"
            data-bs-display="static">
            <input
              class="form-control search-input search"
              type="search"
              placeholder="Buscar pregunta"
              [formControl]="searchQuestionsFormControl"/>
            <span class="fas fa-search search-box-icon"></span>
          </form>
        </div>
      </div>
      <div class="col-md-auto col-sm-12">
        <button
          class="btn btn-primary"
          (click)="openSaveQuestionAnswer()">
          <span class="fas fa-plus"></span>
          Crear pregunta y respuesta
        </button>
      </div>
    </div>
  </div>
  <div class="
    mx-n4 mx-lg-n6
    px-4 px-lg-6
    py-4
    bg-white
    border-top
    border-bottom
    border-200
    position-relative
    top-1">
    <div class="table-responsive table-responsive-sm ms-n1 ps-1 scrollbar">
      <table class="table table-sm w-100">
        <thead>
        <tr>
          <th class="white-space-nowrap align-middle ps-0 w-25" scope="col">
            Pregunta
          </th>
          <th class="align-middle ps-3 w-50 text-center" scope="col">
            Opciones
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="questionsAnswers.length === 0">
          <td colspan="4">
            No hay preguntas registradas.
          </td>
        </tr>
        <tr *ngFor="let questionAnswer of questionsAnswers">
          <td class="align-middle white-space-nowrap ps-0 text-truncate" style="max-width: 600px">
            <a
              class="fw-bold fs-0"
              role="button"
              (click)="openEditQuestionAnswer(questionAnswer)">
              {{ questionAnswer.question }}
            </a>
          </td>
          <td class="align-middle white-space-nowrap ps-3 text-center">
            <div class="d-flex justify-content-center">
              <div class="me-2" (click)="openEditQuestionAnswer(questionAnswer)">
                <i class="fa fa-edit text-primary" role="button"></i>
              </div>
              <div (click)="showDeleteQuestionAnswerConfirmation(questionAnswer)">
                <i class="fa fa-trash text-danger" role="button"></i>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #saveQuestionAnswerOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedQuestionAnswer ? 'Editar pregunta y respuesta' : 'Crear nueva pregunta y respuesta' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige una pregunta y su respuesta correspondiente.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="questionAnswerForm" (submit)="saveQuestionAnswer()">
      <div class="row g-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="question"
              type="text"
              placeholder="Escribe la pregunta"
              formControlName="question"
              [ngClass]="{'is-invalid': questionAnswerForm.get('question').touched && questionAnswerForm.get('question').invalid}">
            <label for="question">Pregunta</label>
            <div *ngIf="questionAnswerForm.get('question').touched && questionAnswerForm.get('question').invalid" class="invalid-feedback">
              Ingrese una pregunta válida, por favor.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <textarea
              class="form-control scrollbar"
              id="answer"
              placeholder="Escribe la respuesta"
              formControlName="answer"
              style="height: 200px"></textarea>
            <label for="answer">Respuesta</label>
            <div *ngIf="questionAnswerForm.get('answer').touched && questionAnswerForm.get('answer').invalid" class="invalid-feedback">
              Ingrese una respuesta válida, por favor.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row g-3 justify-content-end">
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="offcanvas.close()">
                Cancelar
              </button>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                [disabled]="questionAnswerForm.invalid">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
