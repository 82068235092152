
export class UploadClassificationFileRequest {

  classificationId: number;

  fileKey: string;
  fileName: string;
  fileUrl: string;
  fileContentType: string;

  constructor(classificationId: number, fileKey: string, fileName: string, fileUrl: string, fileContentType: string) {
    this.classificationId = classificationId;
    this.fileKey = fileKey;
    this.fileName = fileName;
    this.fileUrl = fileUrl;
    this.fileContentType = fileContentType
  }

}
