<main class="main" id="top">

  <div class="bg-body-emphasis sticky-top" data-navbar-shadow-on-scroll="data-navbar-shadow-on-scroll">
    <nav class="navbar navbar-expand-lg container-small px-3 pt-3 px-lg-7 px-xxl-3">
      <a class="navbar-brand flex-1 flex-lg-grow-0" href="#">
        <div class="d-flex align-items-center">
          <p class="logo-text ms-2">
            Alfonsina
          </p>
        </div>
      </a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item border-bottom border-translucent border-bottom-lg-0">
            <a class="nav-link lh-1 py-0 fs-8 fw-bold py-3" aria-current="page" href="#">¿Quién es Alfonsina?</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <section class="bg-body-emphasis pb-8" id="home">
    <div class="container-small hero-header-container px-lg-7 px-xxl-3">
      <div class="row align-items-center">
        <div class="col-12 col-lg-auto order-0 order-md-1 text-end order-1">
          <div class="position-relative p-5 p-md-7 d-lg-none">
            <div
              class="bg-holder"
              style="background-image:url(./assets/img/bg/bg-23.png); background-size:contain;">
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8 text-lg-start text-center pb-10 order-0 position-relative" style="margin-top: 130px">
          <h1 class="fs-3 fs fw-black mb-4">
            <span class="me-3">Tribunal Electoral del Poder Judicial de la Federación</span>
          </h1>
          <p class="mb-5">
            El TEPJF utiliza a Alfonsina para responder tus preguntas sobre el proceso electoral.
          </p>
          <button type="button" class="btn-support-chat btn btn-lg btn-success rounded-pill me-3" role="button">
            <i class="fa fa-user"></i>
            Pregúntale a Alfonsina
          </button>
        </div>
      </div>
    </div>
  </section>

  <div class="support-chat-container">
    <div class="container-fluid support-chat">
      <div class="card bg-body-emphasis">
        <div class="card-header d-flex flex-between-center px-4 py-3 border-bottom border-translucent">
          <h5 class="mb-0 d-flex align-items-center gap-2">
            Pregúntale a Alfonsina <span class="fa-solid fa-circle text-success fs-11"></span>
          </h5>
        </div>
        <div class="card-body chat p-0">
          <div class="d-flex flex-column-reverse scrollbar p-3">
            <div class="text-center mt-auto">
              <div class="avatar avatar-5xl status-online">
                <img class="rounded-circle border border-3 border-light-subtle" src="./assets/img/icon/avatar.jpg" alt="" />
              </div>
              <h5 class="mt-2 mb-3">
                Alfonsina
              </h5>
              <p class="text-center text-body-emphasis mb-0" *ngIf="messages.length === 0">
                <b>¿Tienes dudas de temas electorales?</b>
                <br>
                Pregúntamelas y buscaré una respuesta.
              </p>
            </div>
          </div>
          <div class="card-body p-3 p-sm-4 scrollbar message-container" #chatScrollContainer>
            <ng-container *ngFor="let messageItem of messages">
              <ng-container *ngIf="messageItem.bot">
                <div class="d-flex chat-message">
                  <div class="d-flex mb-2 flex-1">
                    <div class="w-100 w-xxl-75">
                      <div class="d-flex hover-actions-trigger">
                        <div class="avatar avatar-m me-3 flex-shrink-0">
                          <img class="rounded-circle" src="./assets/img/icon/avatar.jpg">
                        </div>
                        <div class="chat-message-content received me-2">
                          <div class="mb-1 received-message-content border rounded-2 p-3">
                            <ng-container *ngIf="messageItem.botMessage.files?.length > 0">
                              <ng-container class="mb-4" *ngFor="let botFile of messageItem.botMessage.files">
                                <p class="mb-2">
                                  <b>Nombre del documento:</b> <br>
                                  <a href="javascript:void(0);" (click)="getFile(botFile)">
                                    {{ botFile.fileName }}
                                  </a>
                                </p>
                                <p class="mb-2">
                                  <b>Clasificación:</b> <br>
                                  <span class="text-warning">
                                    {{ botFile.classificationName }}
                                  </span>
                                </p>
                                <p class="mb-0">
                                  <b>Año:</b> {{ botFile.year }}
                                </p>
                                <hr>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="messageItem.botMessage.text">
                              <span class="mb-0 d-inline">
                                <p class="mb-0" [innerHTML]="messageItem.botMessage.text"></p>
                                <div class="loader" *ngIf="messageItem.botMessage.text.includes('Escribiendo')"></div>
                              </span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!messageItem.bot">
                <div class="d-flex chat-message">
                  <div class="d-flex mb-2 justify-content-end flex-1">
                    <div class="w-100 w-xxl-75">
                      <div class="d-flex flex-end-center hover-actions-trigger">
                        <div class="chat-message-content me-2">
                          <div class="mb-1 sent-message-content light bg-primary rounded-2 p-3 text-white">
                            <p class="mb-0">
                              {{ messageItem.message }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="card-footer d-flex align-items-center justify-content-end gap-2 border-top border-translucent ps-3 pe-4 py-3">
          <div
            *ngIf="!isRecording"
            class="d-flex align-items-center flex-1 gap-3 border border-translucent rounded-pill px-4">
            <input
              [formControl]="messageFormControl"
              (keydown)="onKeydown($event)"
              class="form-control outline-none border-0 flex-1 fs-9 px-0"
              type="text"
              placeholder="Escribe tu pregunta aquí" />
          </div>
          <div
            *ngIf="isRecording"
            class="d-flex justify-content-end align-items-center mt-2">
            <div class="d-flex align-items-center chat-bar-action-icons">
              <button
                type="button"
                class="btn p-0 border-0 send-btn"
                (click)="abortAudioRecording()">
                <span class="fa-solid fa-trash fs-8"></span>
              </button>
              <div class="spinner-grow spinner-grow-sm px-2 text-danger" role="status"></div>
              <span class="px-2">
                {{ recordedTime }}
              </span>
            </div>
            <button
              type="button"
              id="sendAudioRecordingBtn"
              class="btn p-0 border-0 send-btn"
              (click)="stopAudioRecording()">
              <span class="fa-solid fa-paper-plane fs-8"></span>
            </button>
          </div>
          <button
            class="btn p-0 border-0 send-btn"
            (click)="setMuted()">
            <span
              [ngClass]="{
                'fa-volume-mute text-muted': muted,
                'fa-volume': !muted
              }"
              class="fa-solid fs-8">
            </span>
          </button>
          <button
            class="btn p-0 border-0 send-btn"
            *ngIf="!isRecording"
            (click)="startAudioRecording()">
            <span class="fa-solid fa-microphone fs-8"></span>
          </button>
          <button class="btn p-0 border-0 send-btn"
                  *ngIf="!isRecording"
                  (click)="sendMessage()">
            <span class="fa-solid fa-paper-plane fs-8"></span>
          </button>
        </div>
      </div>
    </div>
    <button class="btn btn-support-chat p-0 border border-translucent">
      <span class="fs-8 btn-text text-primary text-nowrap">
        Pregúntale a Alfonsina
      </span>
      <span class="ping-icon-wrapper mt-n4 ms-n6 mt-sm-0 ms-sm-2 position-absolute position-sm-relative">
        <span class="ping-icon-bg"></span>
        <span class="fa-solid fa-circle ping-icon"></span>
      </span>
      <span class="fa-solid fa-headset text-primary fs-8 d-sm-none"></span>
      <span class="fa-solid fa-chevron-down text-primary fs-7"></span>
    </button>
  </div>
</main>

<ngx-spinner
  [fullScreen]="true"
  type="ball-scale-multiple"
  size="medium"
  style="color: white;">
  <p class="loading-spinner">
    Descargando...
  </p>
</ngx-spinner>
