import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { UserInfo } from "../dto/account/user.info.dto";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private apiUrl: string = `${environment.apiUrl}/users`;

  constructor(
    private http: HttpClient,
  ) {

  }

  getUserInfo(): Observable<UserInfo> {
    const endpoint = `${this.apiUrl}/me`;
    return this.http.get<UserInfo>(endpoint);
  }

}
