<div class="mb-9">
  <div class="row g-3">
    <div class="col-auto">
      <h2 class="mb-2">
        Clasificaciones
      </h2>
      <p class="text-700 lh-sm mb-2">
        Administra las clasificaciones para administrar información a Alfonsina.
      </p>
    </div>
  </div>
  <div class="mb-4">
    <div class="row g-3">
      <div class="col-auto">
        <button
          class="btn btn-primary"
          (click)="openSaveClassification()">
          <span class="fas fa-plus"></span>
          Crear clasificación
        </button>
      </div>
      <div class="col-auto ms-auto">
        <button
          class="btn btn-success"
          (click)="reloadDatabase()">
          <span class="fas fa-refresh"></span>
          Actualizar base de información
        </button>
      </div>
    </div>
  </div>
  <div class="
    mx-n4 mx-lg-n6
    px-4 px-lg-6
    py-4
    bg-white
    border-top
    border-bottom
    border-200
    position-relative
    top-1">
    <div class="table-responsive table-responsive-sm ms-n1 ps-1 scrollbar">
      <table class="table table-sm w-100">
        <thead>
        <tr>
          <th class="white-space-nowrap align-middle ps-0 w-25" scope="col">
            Nombre
          </th>
          <th class="align-middle ps-3 w-50 text-center" scope="col">
            Opciones
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="classifications.length === 0">
          <td colspan="4">
            No hay clasificaciones registradas.
          </td>
        </tr>
        <tr *ngFor="let classification of classifications">
          <td class="align-middle white-space-nowrap ps-0">
            <a
              class="fw-bold fs-0"
              role="button"
              (click)="openFileModal(classification)">
              {{ classification.name }}
            </a>
          </td>
          <td class="align-middle white-space-nowrap ps-3 text-truncate" style="max-width: 100px" *ngIf="false">
            {{ classification.instructions }}
          </td>
          <td class="align-middle white-space-nowrap ps-3 text-center">
            <div class="d-flex justify-content-center">
              <div class="me-2" (click)="openEditClassification(classification)">
                <i class="fa fa-edit text-primary" role="button"></i>
              </div>
              <div (click)="showDeleteClassificationConfirmation(classification)">
                <i class="fa fa-trash text-danger" role="button"></i>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #saveClassificationOffCanvas let-offcanvas>
  <div class="offcanvas-header align-items-start border-bottom flex-column">
    <div class="pt-1 w-100 d-flex justify-content-between align-items-start">
      <div>
        <h4 class="mb-2 me-2 lh-sm">
          {{ selectedClassification ? 'Editar clasificación' : 'Crear nueva clasificación' }}
        </h4>
        <p class="mb-0 fs--1 text-info-500">
          Elige el nombre, descripción e instrucciones.
        </p>
      </div>
      <button
        class="btn p-1 fw-bolder"
        type="button"
        (click)="offcanvas.close()">
        <i class="fas fa-xmark"></i>
      </button>
    </div>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="classificationsForm" (submit)="saveClassification()">
      <div class="row g-3">
        <div class="col-12">
          <div class="form-floating">
            <input
              class="form-control"
              id="name"
              type="text"
              placeholder="Nombre de la clasificación"
              formControlName="name"
              [ngClass]="{'is-invalid': classificationsForm.get('name').touched && classificationsForm.get('name').invalid}">
            <label for="name">Nombre de la clasificación</label>
            <div *ngIf="classificationsForm.get('name').touched && classificationsForm.get('name').invalid" class="invalid-feedback">
              Ingrese un nombre para la clasificación, por favor.
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="false">
          <div class="form-floating">
            <textarea
              class="form-control scrollbar"
              id="instructions"
              placeholder="Instrucciones de la clasificación"
              formControlName="instructions"
              style="height: 400px"></textarea>
            <label for="instructions">Instrucciones de la clasificación</label>
          </div>
        </div>
        <div class="col-12" *ngIf="false">
          <div class="form-floating">
            <textarea
              class="form-control scrollbar"
              id="regExps"
              placeholder="Expresiones regulares"
              formControlName="regExps"
              style="height: 250px"></textarea>
            <label for="regExps">Expresiones regulares</label>
          </div>
        </div>
        <div class="col-12">
          <div class="row g-3 justify-content-end">
            <div class="col-auto">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="offcanvas.close()">
                Cancelar
              </button>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                [disabled]="classificationsForm.invalid">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- Modal para la subida de archivos -->
<ng-template #fileModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Subir archivos para {{ selectedClassification?.name }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <app-classification-files [classification]="selectedClassification"></app-classification-files>
  </div>
</ng-template>
