import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {UserInfo} from "../../../dto/account/user.info.dto";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  public currentTheme: string = 'light';
  public user: UserInfo;

  constructor(
    private readonly authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.checkTheme();
    this.user = this.authService.getUser();
  }

  private checkTheme() {
    const theme = window.localStorage.getItem('phoenixTheme');
    this.currentTheme = theme ? theme : 'light';
  }

  public toggleTheme() {
    if (this.currentTheme === 'light') {
      this.currentTheme = 'dark';
    } else {
      this.currentTheme = 'light';
    }
  }

  public signOut() {
    this.authService.signOut(true);
  }

}
