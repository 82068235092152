
export class QuestionAnswerRequest {

  id: number;
  question: string;
  answer: string;

  constructor(question: string, answer: string) {
    this.question = question;
    this.answer = answer;
  }

}
