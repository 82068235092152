import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged, firstValueFrom, of, switchMap } from 'rxjs';
import { FileService } from "../../../../services/file.service";
import { ClassificationInfo } from "../../../../dto/classification/classification-info.dto";
import { GetSignedUrlRequest } from "../../../../dto/file/get-signed-url-request.dto";

import Uppy, { UppyFile } from "@uppy/core";
import { UppyLocaleValues } from "../../../../constants/uppy-constants";
import { DashboardOptions } from "@uppy/dashboard";
import { ToastrService } from "ngx-toastr";
import { ClassificationFileInfo } from "../../../../dto/classification/files/classification-file-info.dto";
import { ClassificationService } from "../../../../services/classification.service";
import { GetClassificationFilesRequest } from "../../../../dto/classification/files/get-classification-files-request.dto";
import { Page } from "../../../../dto/common/page";
import {
  UploadClassificationFileRequest
} from "../../../../dto/classification/files/upload-classification-file-request.dto";
import { Fancybox } from "@fancyapps/ui";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-classification-files',
  templateUrl: './classification-files.component.html',
  styleUrls: ['./classification-files.component.css']
})
export class ClassificationFilesComponent implements OnInit, OnDestroy {

  protected readonly Math = Math;

  public searchControl: FormControl = new FormControl();

  public totalItems: number = 0;
  public currentPage: number = 0;
  public pageSize: number = 5;

  public uploadingFiles: boolean = false;
  public totalFiles: number = 0;
  public uploadedFiles: number = 0;

  @Input()
  public classification: ClassificationInfo;

  public uppy: Uppy;
  public uppyDashboardProps: DashboardOptions;

  public files: ClassificationFileInfo[] = [];

  constructor(
    private toastr: ToastrService,
    private fileService: FileService,
    private classificationService: ClassificationService,
  ) {

  }

  ngOnInit() {
    this.loadFiles();
    this.initializeUppy();
    this.initSearchControl();
  }

  private initSearchControl() {

    this.searchControl.valueChanges
      .pipe(
        debounceTime(300), // Espera 300ms después de cada pulsación de tecla
        distinctUntilChanged(), // Ignora si el próximo término de búsqueda es igual al anterior
        switchMap((query) => {
          return of(query.trim());
        }),
      ).subscribe((query: string) => {
        this.currentPage = 0;
        this.loadFiles(query);
      });
  }

  private initializeUppy() {

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false,
      locale: UppyLocaleValues,
      restrictions: {
        allowedFileTypes: [
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
        ],
      }
    });

    this.uppy.on('files-added', (files: UppyFile[]) => {
      this.validateUppyFiles(files);
      this.totalFiles = this.uppy.getFiles().length;
      setTimeout(
        () =>
          document
            .querySelector('.uppy-Dashboard-files')
            .classList.add('scrollbar'),
        1,
      );
    });

    this.uppy.on('complete', () => {
      setTimeout(() => {
        this.uppy.setState({
          files: {},
          currentUploads: {},
          totalProgress: 0,
        });
      }, 1000);
    });

    this.uppyDashboardProps = {
      width: '100%',
      height: 250,
      theme: 'light',
      hideUploadButton: true,
    };

    const theme = localStorage.getItem('phoenixTheme');

    if (theme === 'dark' || theme === 'light') {
      this.uppyDashboardProps.theme = theme;
    }
  }

  private validateUppyFiles(uppyFiles: UppyFile[]) {

    const invalidFiles: UppyFile[] = [];

    for (const uppyFile of uppyFiles) {
      const { size } = uppyFile;
    }

    for (const invalidFile of invalidFiles) {
      this.toastr.warning(
        `${(invalidFile.size / 1048576).toFixed(2)} MB`,
        `${invalidFile.name}`,
      );
    }

    if (invalidFiles.length > 0) {
      let message =
        'Los siguientes archivos no pudieron ser agregados debido a que superan el límite de tamaño';
      this.toastr.info(message);
    }
  }

  private loadFiles(query?: string) {

    const getClassificationFilesRequest = new GetClassificationFilesRequest(
      this.classification.id,
      this.currentPage,
      this.pageSize,
      query
    );

    this.classificationService.getClassificationFiles(getClassificationFilesRequest).subscribe({
      next: (page: Page<ClassificationFileInfo>) => {
        this.totalItems = page.totalElements;
        this.files = page.content;
      },
      error: (error: any) => {
        console.error("Error al cargar los archivos de la clasificación:", error);
      }
    });

  }

  public changePage(page: number) {
    this.currentPage = page;
    this.loadFiles();
  }

  public getPaginationArray(): number[] {
    const totalPages = Math.ceil(this.totalItems / this.pageSize);
    const maxPages = 5; // Número máximo de páginas a mostrar

    let startPage: number, endPage: number;
    if (totalPages <= maxPages) {
      // Total pages less than max, so show all pages
      startPage = 0;
      endPage = totalPages - 1;
    } else {
      // More than max, so calculate start and end pages
      if (this.currentPage <= Math.floor(maxPages / 2)) {
        startPage = 0;
        endPage = maxPages - 1;
      } else if (this.currentPage + Math.floor(maxPages / 2) >= totalPages) {
        startPage = totalPages - maxPages;
        endPage = totalPages - 1;
      } else {
        startPage = this.currentPage - Math.floor(maxPages / 2);
        endPage = this.currentPage + Math.floor(maxPages / 2);
      }
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  }

  public deleteFile(file: ClassificationFileInfo) {

    if (!confirm('¿Estás seguro de que deseas eliminar este archivo?')) {
      return;
    }

    this.classificationService.deleteClassificationFile(file.id).subscribe({
      next: () => {
        this.toastr.success('Archivo eliminado correctamente');
        this.loadFiles();
      },
      error: (error: any) => {
        console.error("Error al eliminar el archivo:", error);
      }
    });
  }

  public openFile(url: string, type: string) {

    // check if the file is a docx, if this is a docx then create a link with the url and download

    if (url.includes('.docx')) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url;
      link.click();
      return;
    }

    new Fancybox(
      [
        {
          src: url,
          type: type as any,
        },
      ]
    );
  }

  public openGeneratedFile(url: string) {

    this.fileService.readTxtFile(url).subscribe({
      next: (data: any) => {
        const pre = `
          <pre style="white-space: pre-wrap; word-wrap: break-word;">
            ${data}
          </pre>
        `;
        new Fancybox(
          [
            {
              src: pre,
              type: 'html',
            },
          ]
        );
      },
      error: (error: any) => {
        console.error("Error al abrir el archivo generado:", error);
      }
    });

  }

  public async uploadFiles() {
    const uppyFiles = this.uppy.getFiles();
    this.uploadingFiles = true;
    this.uploadedFiles = 0;
    for (const uppyFile of uppyFiles) {
      const file = uppyFile.data as File;
      await this.uploadFile(file);
      this.uppy.removeFile(uppyFile.id);
      this.uploadedFiles++;
      this.currentPage = 0;
      this.loadFiles();
    }
    this.totalFiles = 0;
    this.uploadingFiles = false;
  }

  public async uploadFile(file: File) {

    const prefix: string = `classifications/${this.classification.code}`;

    const getSignedUrlRequest = new GetSignedUrlRequest(prefix, file.name, file.type);
    const getSignedUrlResponse = await firstValueFrom(this.fileService.getSignedFileUrl(getSignedUrlRequest));
    const { key, signedUrl, publicUrl } = getSignedUrlResponse;

    await firstValueFrom(this.fileService.uploadMedia(signedUrl, file));

    const uploadClassificationFileRequest = new UploadClassificationFileRequest(
      this.classification.id,
      key, file.name, publicUrl, file.type
    );

    await new Promise<void>((resolve, reject) => {
      this.classificationService.uploadClassificationFile(uploadClassificationFileRequest).subscribe({
        next: () => {
          console.log("Archivo subido correctamente");
          resolve();
        },
        error: (error: any) => {
          console.error("Error al subir el archivo:", error);
          resolve();
        }
      });
    });

  }

  // private wait(ms: number) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  ngOnDestroy() {
    this.uppy.close();
  }

}
