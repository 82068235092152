import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";
import { AuthService } from "../../../services/auth.service";
import { UserInfo } from "../../../dto/account/user.info.dto";
import { TokenResponse } from "../../../dto/auth/token-response.dto";
import { TokenRequest } from "../../../dto/auth/token-request.dto";
import { UserService } from "../../../services/user.service";
import { SpinnerService } from "../../../services/data/spinner.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
})
export class SignInComponent implements OnInit {

  public signInForm: FormGroup;
  public loading: boolean = false;
  public error: string = '';

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly spinnerService: SpinnerService,
  ) {

  }

  ngOnInit() {
    this.initializeForm();
  }

  private initializeForm() {

    this.signInForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(4)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });

  }

  public signIn() {

    const { username, password } = this.signInForm.controls;

    if (this.signInForm.valid) {

      this.loading = true;
      this.spinnerService.show('Iniciando sesión...');

      const tokenRequest = new TokenRequest();
      tokenRequest.username = username.value;
      tokenRequest.password = password.value;

      this.authService.getToken(tokenRequest)
        .subscribe({
          next: (tokenResponse: TokenResponse) => {

            setTimeout(() => {
              this.loading = false;
              this.spinnerService.hide();
              this.processSignIn(tokenResponse);
            }, 250);

          },
          error: (error: any) => {
            this.loading = false;
            this.spinnerService.hide();
            this.error = 'Credenciales incorrectas. Por favor, verifica tu correo y contraseña.';
            console.error('Error en el inicio de sesión:', error);
          }
        });
    }

  }

  public processSignIn(tokenResponse: TokenResponse) {

    this.authService.saveAuthentication(tokenResponse);

    this.userService.getUserInfo().subscribe({
      next: (user: UserInfo) => {

        this.authService.saveUser(user);

        let route: string = '/admin';
        this.router.navigate([route]).then(() => {});
      },
      error: (error: any) => {
        console.error('Error al obtener la información del usuario:', error);
      }
    });
  }

}
