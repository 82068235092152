import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { TokenRequest } from "../dto/auth/token-request.dto";
import { TokenResponse } from "../dto/auth/token-response.dto";
import { RefreshTokenRequest } from "../dto/auth/refresh-token-request.";
import { UserInfo } from "../dto/account/user.info.dto";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl: string = `${environment.apiUrl}/auth`;
  private authenticationKey: string = 'auth';
  private userKey: string = 'user';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {

  }

  getToken(tokenRequest: TokenRequest): Observable<TokenResponse> {
    const endpoint = `${this.apiUrl}/token`;
    return this.http.post<TokenResponse>(endpoint, tokenRequest);
  }

  refreshToken(refreshTokenRequest: RefreshTokenRequest): Observable<TokenResponse> {
    const endpoint = `${this.apiUrl}/refresh-token`;
    return this.http.post<TokenResponse>(endpoint, refreshTokenRequest);
  }

  isAuthenticated(): boolean {
    const authentication = this.getAuthentication();
    if (authentication) return authentication.expiresAt > new Date().getTime();
    return false;
  }

  saveAuthentication(tokenResponse: TokenResponse) {
    localStorage.setItem(this.authenticationKey, JSON.stringify(tokenResponse));
  }

  getAuthentication(): TokenResponse {
    const authenticationString = localStorage.getItem(this.authenticationKey);
    if (!authenticationString) return null;
    return JSON.parse(authenticationString);
  }

  saveUser(user: UserInfo) {
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  getUser(): UserInfo {
    const userString = localStorage.getItem(this.userKey);
    if (!userString) return null;
    return JSON.parse(userString);
  }

  signOut(redirect?: boolean) {

    localStorage.removeItem(this.authenticationKey);
    localStorage.removeItem(this.userKey);

    if (redirect)
      this.router.navigate(['sign-in']).then(() => {
        console.log('Redirected to sign-in');
      });
  }

}
