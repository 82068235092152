import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { StartChatRequest } from "../dto/chat/start-chat-request.dto";
import { StartChatResponse } from "../dto/chat/start-chat-response.dto";
import { GetFileRequest } from "../dto/chat/get-file-request.dto";

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  private apiUrl: string = `${environment.apiUrl}/chat`;

  constructor(
    private http: HttpClient,
  ) {

  }

  public startChat(startChatRequest: StartChatRequest): Observable<StartChatResponse> {
    const endpoint = `${this.apiUrl}/start`;
    return this.http.post<StartChatResponse>(endpoint, startChatRequest);
  }

  public getFile(getFileRequest: GetFileRequest) {
    const endpoint = `${this.apiUrl}/get-file`;
    return this.http.post(endpoint, getFileRequest);
  }

}
