import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlfonsinaComponent } from "./components/alfonsina/alfonsina.component";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { ClassificationsComponent } from "./components/admin/classifications/classifications.component";
import {QuestionsAnswersComponent} from "./components/admin/questions-answers/questions-answers.component";
import {SignInComponent} from "./components/auth/sign-in/sign-in.component";
import {AuthGuard} from "./guard/auth.guard";

const routes: Routes = [
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'classifications', component: ClassificationsComponent },
      { path: 'questions-answers', component: QuestionsAnswersComponent },
      {
        path: '**',
        redirectTo: 'classifications'
      }
    ]
  },
  {
    path: '',
    component: AlfonsinaComponent,
  },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
