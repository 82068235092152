import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {SpinnerService} from "../../services/data/spinner.service";

@Component({
  selector: 'app-main-layout',
  template: `
    <div class="main" id="top">
      <nav class="navbar navbar-vertical navbar-expand-lg">
        <app-sidebar></app-sidebar>
      </nav>
      <nav
        class="navbar navbar-top fixed-top navbar-expand-lg">
        <app-header></app-header>
      </nav>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <ngx-spinner [fullScreen]="true" [type]="spinnerType" size="medium" style="color: white;">
        <p class="loading-spinner">{{ spinnerMessage ? spinnerMessage : '' }}</p>
      </ngx-spinner>
    </div>
  `,
  styleUrls: ['./admin-layout.component.css'],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {

  public spinnerMessage: string = '';
  public spinnerType: string = 'square-spin';
  private spinnerSubscription: Subscription;

  constructor(
    private spinnerService: SpinnerService,
  ) {

  }

  ngOnInit() {
    this.initializeSpinnerEvent();
  }

  ngOnDestroy() {
    this.spinnerSubscription.unsubscribe();
  }

  private initializeSpinnerEvent() {
    this.spinnerSubscription = this.spinnerService.spinnerSubject
      .subscribe((spinnerData: any) => {
        this.spinnerMessage = spinnerData.message;
        this.spinnerType = spinnerData.type;
      });
  }

}
