<nav
  class="navbar navbar-top fixed-top navbar-expand"
  id="navbarCombo"
  data-navbar-top="combo"
  data-move-target="#navbarVerticalNav">
  <div
    class="collapse navbar-collapse justify-content-between"
    id="navbarTopCollapse">
    <div class="navbar-logo">
      <button
        class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
        type="button">
        <span class="navbar-toggle-icon">
          <span class="toggle-line"></span>
        </span>
      </button>
      <a class="navbar-brand me-1 me-sm-3" [routerLink]="['']">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center py-0">
            Alfonsina
          </div>
        </div>
      </a>
    </div>
    <ul class="navbar-nav navbar-nav-icons flex-row">
      <li class="nav-item">
        <ng-container *ngTemplateOutlet="themeStyle"></ng-container>
      </li>
      <li class="nav-item dropdown">
        <ng-container *ngTemplateOutlet="userAuthInformation"></ng-container>
      </li>
    </ul>
  </div>
</nav>

<!-- Theme control -->
<ng-template #themeStyle>
  <div class="theme-control-toggle px-2">
    <input (click)="toggleTheme()"
           class="form-check-input ms-0 theme-control-toggle-input"
           type="checkbox"
           data-theme-control="phoenixTheme"
           value="dark"
           id="themeControlToggle"
    />
    <label
      class="mb-0 theme-control-toggle-label theme-control-toggle-light"
      for="themeControlToggle"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Cambiar tema">
      <i class="fa fa-moon"></i>
    </label>
    <label
      class="mb-0 theme-control-toggle-label theme-control-toggle-dark"
      for="themeControlToggle"
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title="Cambiar tema">
      <i class="fa fa-sun"></i>
    </label>
  </div>
</ng-template>

<!-- Authenticated user -->
<ng-template #userAuthInformation>
  <a
    class="nav-link lh-1 pe-0 cursor-pointer"
    id="navbarDropdownUser"
    role="button"
    data-bs-toggle="dropdown"
    data-bs-auto-close="outside">
    <div class="avatar avatar-l ">
      <img
        class="rounded-circle"
        src="./assets/img/user.png"
        alt=""
      />
    </div>
  </a>
  <div
    class="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300">
    <div class="card position-relative border-0">
      <div class="card-body p-0">
        <div class="text-center pt-4 pb-3">
          <div class="avatar avatar-xl ">
            <img
              class="rounded-circle "
              src="./assets/img/user.png"
              alt=""
            />
          </div>
          <h6 class="mt-2">
            {{ user?.username }}
          </h6>
        </div>
      </div>
      <div class="card-footer border-top">
        <div class="px-1">
          <a class="btn btn-phoenix-secondary d-flex flex-center w-100"
            (click)="signOut()">
            <span class="me-2" data-feather="log-out"></span>
            Cerrar sesión
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
