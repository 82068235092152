// Angular
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

// NgBootstrap
import { NgbModal, NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";

// Toastr
import { ToastrService } from "ngx-toastr";

// Classification Models
import { ClassificationInfo } from "../../../dto/classification/classification-info.dto";

// Services
import { SpinnerService } from "../../../services/data/spinner.service";
import { ClassificationRequest } from "../../../dto/classification/classification-request.dto";
import { ClassificationService } from "../../../services/classification.service";

@Component({
  selector: 'app-classifications',
  templateUrl: './classifications.component.html',
  styleUrls: ['./classifications.component.css']
})
export class ClassificationsComponent implements OnInit, AfterViewInit {

  public classificationsForm: FormGroup;

  @Input()
  public selectedClassification: ClassificationInfo;

  public classifications: ClassificationInfo[] = [];
  public loadingClassifications: boolean = false;

  // OffCanvas
  @ViewChild("saveClassificationOffCanvas")
  public saveClassificationOffCanvas: TemplateRef<any>;

  @ViewChild('fileModal')
  public fileModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private classificationService: ClassificationService,
    private offCanvasService: NgbOffcanvas,
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.loadClassifications();
  }

  ngAfterViewInit() {

  }

  private initializeForm() {

    this.selectedClassification = undefined;

    this.classificationsForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      instructions: new FormControl("",),
      regExps: new FormControl(""),
    });
  }

  public reloadDatabase() {

    if (confirm("¿Está seguro que desea recargar la base de datos?")) {

      this.spinnerService.show('Recargando base de datos...');

      this.classificationService.reloadDatabase().subscribe({
        next: (data: any) => {

          const jsonStr = JSON.stringify(data, null, 2);

          // Crear un Blob con el contenido JSON
          const blob = new Blob([jsonStr], { type: 'application/json' });

          // Crear un enlace temporal para descargar el archivo
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `output.json`;

          // Simular el clic para descargar el archivo
          link.click();

          // Limpiar el URL después de la descarga
          URL.revokeObjectURL(link.href);

          this.spinnerService.hide();
          this.toastr.success("Base de datos recargada exitosamente");
        },
        error: (error: any) => {
          this.spinnerService.hide();
          console.error("Error al recargar la base de datos:", error);
          this.toastr.error("Error al recargar la base de datos");
        },
      });
    }

  }

  private loadClassifications() {

    this.loadingClassifications = true;

    this.spinnerService.show('Cargando clasificaciones...');

    this.classificationService.getClassifications().subscribe({
      next: (classifications: ClassificationInfo[]) => {
        this.classifications = classifications;
        this.loadingClassifications = false;
        this.spinnerService.hide();
      },
      error: (error: any) => {
        this.loadingClassifications = false;
        this.spinnerService.hide();
        console.error("Error en la carga de clasificaciones:", error);
      },
    });
  }

  public openSaveClassification() {

    this.offCanvasService.open(
      this.saveClassificationOffCanvas,
      {
        position: "end",
      }
    );
  }

  public openEditClassification(classification: ClassificationInfo) {

    this.selectedClassification = classification;

    this.classificationsForm.get("name").setValue(classification.name);
    this.classificationsForm.get("instructions").setValue(classification.instructions);
    this.classificationsForm.get("regExps").setValue(classification.regExps);

    this.openSaveClassification();
  }

  public showDeleteClassificationConfirmation(classification: ClassificationInfo) {

    if (confirm("¿Está seguro que desea eliminar la clasificación?")) {
      this.classificationService.deleteClassification(classification.id).subscribe({
        next: () => {
          this.loadClassifications();
          this.toastr.success("Clasificación eliminada exitosamente");
        },
        error: (error: any) => {
          console.error("Error al eliminar la clasificación:", error);
          this.toastr.error("Error al eliminar la clasificación");
        },
      });
    }
  }

  public openFileModal(classification: ClassificationInfo) {
    this.selectedClassification = classification;
    const modalRef = this.modalService.open(this.fileModal, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
    });

    modalRef.dismissed.subscribe(() => {
      this.initializeForm();
    });

  }

  public saveClassification() {

    const { name, instructions, regExps } = this.classificationsForm.value;

    let classificationId: number = this.selectedClassification ? this.selectedClassification.id : null;

    const classificationRequest = new ClassificationRequest(name, instructions, regExps);
    classificationRequest.id = classificationId;

    this.classificationService.saveClassification(classificationRequest).subscribe({
      next: () => {
        this.selectedClassification = undefined;
        this.loadClassifications();
        this.offCanvasService.dismiss();
        this.toastr.success("Clasificación creada exitosamente");
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const { error } = httpErrorResponse;
        console.log("Error al guardar clasificación:", error);
      },
    });
  }

}
