import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as RecordRTC from "recordrtc";

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {

  private audioStream: MediaStream;
  private audioRecorder: RecordRTC.StereoAudioRecorder;
  private interval: any;
  private startTime: number;

  private recordingDoneEmitter: Subject<any> = new Subject<any>();
  private recordingTimeEmitter: Subject<string> = new Subject<string>();
  private recordingFailedEmitter: Subject<void> = new Subject<void>();

  public getRecordedBlob(): Observable<any> {
    return this.recordingDoneEmitter.asObservable();
  }

  public getRecordedTime(): Observable<string> {
    return this.recordingTimeEmitter.asObservable();
  }

  public recordingFailed(): Observable<void> {
    return this.recordingFailedEmitter.asObservable();
  }

  public startRecording() {

    if (this.audioRecorder) return;

    this.recordingTimeEmitter.next('00:00');

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((audioStream: MediaStream) => {

        this.audioStream = audioStream;
        this.record();

      })
      .catch(() => {
        alert('Debes aceptar el permiso para poder grabar notas de voz');
        this.recordingFailedEmitter.next();
      });
  }

  public abortRecording() {
    this.stopMedia();
  }

  private record() {

    this.audioRecorder = new RecordRTC.StereoAudioRecorder(this.audioStream, {
      type: 'audio',
      mimeType: 'audio/webm',
    });

    this.audioRecorder.record();
    this.startTime = Date.now();
    this.interval = setInterval(() => {
      const elapsedTime = this.calculateElapsedTime();
      const time = this.formatTime(elapsedTime);
      this.recordingTimeEmitter.next(time);
    }, 1000);
  }

  private calculateElapsedTime(): number {
    const currentTime = Date.now();
    return Math.floor((currentTime - this.startTime) / 1000);
  }

  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = this.padWithZero(minutes);
    const formattedSeconds = this.padWithZero(remainingSeconds);
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  private padWithZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  public stopRecording() {

    if (!this.audioRecorder) return;

    const recordingSuccess = (blob: any) => {
      if (!this.startTime) return;
      this.stopMedia();
      const audioName = encodeURIComponent(`audio_${Date.now()}.mp3`);
      this.recordingDoneEmitter.next({ blob, audioName });
    }

    this.audioRecorder.stop(recordingSuccess);
  }

  private stopMedia() {
    if (!this.audioRecorder) return;
    clearInterval(this.interval);
    this.audioRecorder = null;
    this.startTime = null;
    if (this.audioStream) {
      this.audioStream.getTracks().forEach((track) => track.stop());
      this.audioStream = null;
    }
  }
}
