// Angular
import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";

// NgBootstrap
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";

// Toastr
import { ToastrService } from "ngx-toastr";

// QuestionAnswer Models
import { QuestionAnswerInfo } from "../../../dto/question-answer/question-answer-info.dto";

// Services
import { SpinnerService } from "../../../services/data/spinner.service";
import { QuestionAnswerRequest } from "../../../dto/question-answer/question-answer-request.dto";
import { QuestionAnswerService } from "../../../services/question-answer.service";
import {debounceTime, distinctUntilChanged, of, switchMap} from "rxjs";

@Component({
  selector: 'app-questions-answers',
  templateUrl: './questions-answers.component.html',
  styleUrls: ['./questions-answers.component.css']
})
export class QuestionsAnswersComponent implements OnInit {

  public questionAnswerForm: FormGroup;
  public searchQuestionsFormControl: FormControl = new FormControl("");

  @Input()
  public selectedQuestionAnswer: QuestionAnswerInfo;

  public questionsAnswers: QuestionAnswerInfo[] = [];
  public loadingQuestionsAnswers: boolean = false;

  // OffCanvas
  @ViewChild("saveQuestionAnswerOffCanvas")
  public saveQuestionAnswerOffCanvas: TemplateRef<any>;

  constructor(
    private toastr: ToastrService,
    private spinnerService: SpinnerService,
    private questionAnswerService: QuestionAnswerService,
    private offCanvasService: NgbOffcanvas,
  ) {
  }

  ngOnInit() {
    this.initializeForm();
    this.loadQuestionsAnswers();
    this.initializeSearchQuestionsFormControl();
  }

  private initializeForm() {

    this.selectedQuestionAnswer = undefined;

    this.questionAnswerForm = new FormGroup({
      question: new FormControl("", [Validators.required]),
      answer: new FormControl("", [Validators.required]),
    });
  }

  initializeSearchQuestionsFormControl() {
    this.searchQuestionsFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((query) => {
          return of(query.trim());
        })
      )
      .subscribe((searchTerm: string) => {
        this.loadQuestionsAnswers();
      });
  }

  private loadQuestionsAnswers() {

    this.loadingQuestionsAnswers = true;

    this.spinnerService.show('Cargando preguntas y respuestas...');

    const term: string = this.searchQuestionsFormControl.value ? this.searchQuestionsFormControl.value : '';

    this.questionAnswerService.getQuestionAnswers(term).subscribe({
      next: (questionsAnswers: QuestionAnswerInfo[]) => {
        this.questionsAnswers = questionsAnswers;
        this.loadingQuestionsAnswers = false;
        this.spinnerService.hide();
      },
      error: (error: any) => {
        this.loadingQuestionsAnswers = false;
        this.spinnerService.hide();
        console.error("Error en la carga de preguntas y respuestas:", error);
      },
    });
  }

  public openSaveQuestionAnswer() {

    const modalRef = this.offCanvasService.open(
      this.saveQuestionAnswerOffCanvas,
      {
        position: "end",
      }
    );

    modalRef.hidden.subscribe(() => {
      this.questionAnswerForm.reset();
      this.selectedQuestionAnswer = undefined;
    });
  }

  public openEditQuestionAnswer(questionAnswer: QuestionAnswerInfo) {

    this.selectedQuestionAnswer = questionAnswer;

    this.questionAnswerForm.get("question").setValue(questionAnswer.question);
    this.questionAnswerForm.get("answer").setValue(questionAnswer.answer);

    this.openSaveQuestionAnswer();
  }

  public showDeleteQuestionAnswerConfirmation(questionAnswer: QuestionAnswerInfo) {

    if (confirm("¿Está seguro que desea eliminar la pregunta y respuesta?")) {
      this.spinnerService.show("Eliminando pregunta y respuesta...");
      this.questionAnswerService.deleteQuestionAnswer(questionAnswer.id).subscribe({
        next: () => {
          this.spinnerService.hide();
          this.loadQuestionsAnswers();
          this.toastr.success("Pregunta y respuesta eliminadas exitosamente");
        },
        error: (error: any) => {
          console.error("Error al eliminar la pregunta y respuesta:", error);
          this.toastr.error("Error al eliminar la pregunta y respuesta");
        },
      });
    }
  }

  public saveQuestionAnswer() {

    const {question, answer} = this.questionAnswerForm.value;

    let questionAnswerId: number = this.selectedQuestionAnswer ? this.selectedQuestionAnswer.id : null;

    const questionAnswerRequest = new QuestionAnswerRequest(question, answer);
    questionAnswerRequest.id = questionAnswerId;

    this.spinnerService.show("Guardando pregunta y respuesta...");

    this.questionAnswerService.saveQuestionAnswer(questionAnswerRequest).subscribe({
      next: () => {
        this.spinnerService.hide();
        this.selectedQuestionAnswer = undefined;
        this.loadQuestionsAnswers();
        this.offCanvasService.dismiss();
        this.toastr.success("Pregunta y respuesta creadas exitosamente");
      },
      error: (httpErrorResponse: HttpErrorResponse) => {
        const {error} = httpErrorResponse;
        console.log("Error al guardar pregunta y respuesta:", error);
      },
    });
  }

}
