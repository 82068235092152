import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpContextToken } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import {AuthService} from "../auth.service";
import {RefreshTokenRequest} from "../../dto/auth/refresh-token-request.";

export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldBypass(request)) {
      return next.handle(request);
    }
    return this.handleAuthorizedRequest(request, next);
  }

  private shouldBypass(request: HttpRequest<any>): boolean {
    return request.context.get(BYPASS_LOG) === true ||
      request.headers.has('Ignore-Authorization') ||
      request.url.includes("auth/token") ||
      request.url.includes("auth/refresh-token");
  }

  private handleAuthorizedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authentication = this.authService.getAuthentication();
    if (authentication && authentication.accessToken && this.authService.isAuthenticated()) {
      return next.handle(this.addAuthorizationHeader(request));
    } else {
      return this.refreshTokenAndRetry(request, next);
    }
  }

  private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.authService.getAuthentication().accessToken;
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  private refreshTokenAndRetry(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authentication = this.authService.getAuthentication();

    if (authentication) {
      const refreshToken = this.authService.getAuthentication().refreshToken;
      const refreshTokenRequest = new RefreshTokenRequest(refreshToken);

      return this.authService.refreshToken(refreshTokenRequest).pipe(
        switchMap(refreshTokenResponse => {
          this.authService.saveAuthentication(refreshTokenResponse);
          return next.handle(this.addAuthorizationHeader(request));
        })
      );
    }

    return next.handle(request);
  }
}
