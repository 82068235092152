<div class="container">
  <div class="row flex-center min-vh-100 py-5">
    <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">

      <h2 class="text-center mb-4">Iniciar sesión</h2>

      <form class="needs-validation mb-2" [formGroup]="signInForm" (submit)="signIn()">
        <div class="mb-3 text-start">
          <label class="form-label" for="username">Nombre de usuario</label>
          <div class="form-icon-container">
            <input
              class="form-control"
              id="username"
              type="text"
              placeholder="admin"
              formControlName="username"
              [ngClass]="{
                'is-invalid': signInForm.get('username').touched && signInForm.get('username').invalid,
                'is-valid': signInForm.get('username').valid
              }"
              required
            />
            <div
              class="invalid-feedback"
              *ngIf="signInForm.get('username').touched && signInForm.get('username').invalid">
              Ingresa un nombre de usuario válido.
            </div>
          </div>
        </div>

        <div class="mb-3 text-start">
          <label class="form-label" for="password">Contraseña</label>
          <div class="form-icon-container">
            <input
              class="form-control pe-6"
              id="password"
              type="password"
              placeholder="Contraseña"
              formControlName="password"
              [ngClass]="{
                'is-invalid': signInForm.get('password').touched && signInForm.get('password').invalid,
                'is-valid': signInForm.get('password').valid
              }"
              required
            />
            <div
              class="invalid-feedback"
              *ngIf="signInForm.get('password').touched && signInForm.get('password').invalid">
              La contraseña es requerida y debe tener al menos 8 caracteres.
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-primary w-100 mb-3"
          [disabled]="signInForm.invalid">
          {{ loading ? 'Iniciando sesión...' : 'Iniciar sesión' }}
        </button>

        <div class="invalid-feedback d-block mt-0 mb-2" *ngIf="error">
          <p class="text-xl-center fs--1">{{ error }}</p>
        </div>
      </form>

    </div>
  </div>
</div>
