import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
import { QuestionAnswerInfo } from "../dto/question-answer/question-answer-info.dto";
import { QuestionAnswerRequest } from "../dto/question-answer/question-answer-request.dto";

@Injectable({
  providedIn: 'root'
})
export class QuestionAnswerService {

  private baseUrl: string = `${environment.apiUrl}/questions-answers`;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
  ) { }

  getQuestionAnswers(term: string): Observable<QuestionAnswerInfo[]> {
    let url = `${this.baseUrl}`;
    if (term) url = `${url}?term=${term}`;
    return this.http.get<QuestionAnswerInfo[]>(url);
  }

  saveQuestionAnswer(questionAnswerRequest: QuestionAnswerRequest): Observable<QuestionAnswerInfo> {
    const url = `${this.baseUrl}`;
    return this.http.post<QuestionAnswerInfo>(url, questionAnswerRequest, { headers: this.headers });
  }

  deleteQuestionAnswer(id: number): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url);
  }

}
